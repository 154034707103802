@import "../node_modules/ngx-toastr/toastr.css";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  padding: 0;
  font-size: 0.9rem;
}

.disabled {
  cursor: text;
}
.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #2A79FF!important;
}
a {
  text-decoration: none !important;
}

a:hover {
  cursor: pointer !important;
  text-decoration: underline;
}

.list-inline-item.disabled a {
  color: black;
  cursor: text;
}

.list-group-item {
  border: none !important;
  transition: 0.2s ease-in-out !important;
}

.accordion-item {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-bottom: 2px solid #dfdfdf !important;
}

.accordion-button {
  background-color: #ecf4f7 !important;
  font-size: 0.95rem !important;
}

.list-group-item:hover {
  background-color: rgb(224, 224, 224) !important;
  transition: 0.2s ease-in-out !important;
  cursor: pointer !important;
}

.logo {
  padding-top: 2px;
  padding-bottom: 4px;
  height: 50px;
  width: 115px;
}

.card-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  background: #cce0e95e;
}

.item-preview {
  height: 150px;
  width: 150px;
}

.span-link {
  color: #158ae2;
}

.span-link:hover {
  cursor: pointer;
  color: #0056b3;
  text-decoration: underline;
}

.nav-link {
  transition: 0.2s ease-in-out !important;
}

.nav-link:hover {
  text-decoration: none;
  background-color: #002364 !important;
  transition: 0.2s ease-in-out !important;
}

.table-scroll {
  overflow-x: auto !important;
  overflow-y: hidden !important;
}

th {
  border: 0px !important;
  border-bottom: 2px solid #dee2e6 !important;
  padding: 0.75rem !important;
  color: #495057 !important;
  background-color: #e9ecef !important;
  border-color: #dee2e6 !important;
}

td {
  border: 0px !important;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem !important;
  border-top: 2px solid #dee2e6 !important;
}

.btn {
  border-radius: 0.25rem !important;
  vertical-align: middle;
}

.dropdown-item {
  text-decoration: none !important;
}

.btnHover:hover {
  background-color: #0b60df !important;
  border: 1px solid rgba(128, 128, 128, 0) !important;
}

.modal-dialog {
  max-width: 65% !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.router-link-active {
  color: #158ae2 !important;
}

.ui-dropdown {
  min-width: 100% !important;
}

.ui-paginator .ui-dropdown {
  min-width: auto !important;
}

.ui-dropdown {
  height: 32px !important;
  border: 1px solid #ced4da !important;
}

.ui-dropdown-label {
  height: 28px !important;
  line-height: 16px !important;
}

.ui-dropdown-trigger {
  height: 28px !important;
}

.ui-dropdown-trigger-icon {
  margin-left: -1em !important;
  margin-top: -0.4em !important;
  font-size: 0.75rem !important;
  font-weight: 400 !important;
  color: black !important;
}

.span-grey {
  color: grey !important;
}
.span-grey:hover {
  color: #0b60df !important;
  cursor: pointer;
  text-decoration: underline !important;
}

.ui-tree {
  width: auto !important;
}
.ui-paginator-bottom {
  float: left !important;
  margin-bottom: 130px !important;
  /* width: 515px !important; */
  border: none !important;
  background-color: white !important;
}
.ui-paginator-bottom:before {
  padding-top: 5px !important;
  float: left !important;
  content: "View per page";
}
.ui-paginator .ui-dropdown{
  float: left !important;
}

input::-webkit-input-placeholder {
  color: #797979 !important;
}
.ui-placeholder{
  color: #797979 !important;
}

select {
  color: #797979 !important;
}
option:not(:first-of-type) {
  color: black !important;
}
